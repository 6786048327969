import { combineConstants } from './../../lib/Functions';
import { AnyAction } from 'redux';
import Immutable from 'immutable';
import { Cookies } from 'react-cookie';
import Config from '../Config';
const initialState = {};

const cookies = new Cookies();
const c = combineConstants({});
export default (state = initialState, action: AnyAction) => {
  let { payload } = action;

  switch (action.type) {
    case c.LOGIN + c.SUCCEEDED: {
      cookies.set(Config.JWT_COOKIE_NAME!, payload.jwt);
      return state;
    }
    default:
      return state;
  }
};
