/*
Author: Seif Sgayer (seif@sghaier.me)
globalActions.ts (c) 2020
Desc: description
Created:  2020-05-02T14:27:00.608Z
Modified: 2020-06-23T23:01:13.771Z
*/
import * as c from './globalConstants';

export const dummy = () => ({ type: null, payload: null });
