import React from 'react';
import { Result } from 'antd';
import { Button } from '@material-ui/core';
import { Routes } from '../lib/Functions';
import { Link, Redirect, useHistory } from 'react-router-dom';
import logo from '../assets/logo.png';

const NotFound = React.memo((props: any) => {
  const h = useHistory();
  React.useEffect(() => {
    // h.push(Routes.ROOT);
    h.replace(Routes.ROOT);
  }, []);
  return (
    <div className="loadingPage">
      <div>
        <img src={logo} />
        <div>
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Link to={Routes.ROOT}>
                <Button variant="outlined" color="secondary">
                  Back Home
                </Button>
              </Link>
            }
          />
        </div>
      </div>
    </div>
  );
});

export default NotFound;
