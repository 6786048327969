import { AxiosRequestConfig } from 'axios';
import RegisterGlobalAPIs from './App/GlobalApis';
import { getCookie } from './lib/Functions';
import SafeFramework from '@seifsg/safe-framework';
import Config from './App/Config';
import log from 'safe-logger';

export default function InitApp() {
  // initialize logger
  if (Config.MODE !== 'dev') {
    log.goSilent();
  }
  log.i();

  // init SafeFramework
  const publicAxiosOptions: AxiosRequestConfig = {
    baseURL: Config.SERVER_URL,
    withCredentials: true,
  };
  let token = getCookie(Config.JWT_COOKIE_NAME!);
  if (token) {
    publicAxiosOptions.headers = {
      Authorization: token,
    };
  }

  SafeFramework.updateOptions({
    publicAxiosOptions,
  });
  RegisterGlobalAPIs();
}
