import React from 'react';
import logo from '../assets/logo.png';

const Loading = React.memo((props: any) => {
  return (
    <div className="loadingPage">
      <div>
        <img src={logo} />
        <p>Loading...</p>
      </div>
    </div>
  );
});

export default Loading;
