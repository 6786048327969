import React, { FunctionComponent, useEffect } from 'react';
import { Route, Switch, RouteProps, useHistory } from 'react-router';

import log from 'safe-logger';
import { useDispatch } from 'react-redux';
import Loading from '../../Components/Loading';
import { Routes } from '../../lib/Functions';
import NotFound from '../../Components/NotFound';
import SafeFramework from '@seifsg/safe-framework';
import { processLogin } from '../../lib/Auth';

const Login = React.lazy(() => import('../../Pages/Login'));
const Register = React.lazy(() => import('../../Pages/Register'));
const ContentDownload = React.lazy(() => import('../../Pages/ContentDownload'));

const AdminDashboard = React.lazy(() => import('../../Pages/AdminPanel/Index'));
const UserDashboard = React.lazy(() => import('../../Pages/UserPanel/Index'));

const AdminRoute: FunctionComponent<{} & RouteProps> = (props: any) => {
  const role = SafeFramework.getUserRole();

  if (role !== 'admin') return <Route {...props} component={Login} />;
  else return <Route {...props} />;
};

const UserRoute: FunctionComponent<{} & RouteProps> = (props: any) => {
  const role = SafeFramework.getUserRole();
  const h = useHistory();
  if (role === 'admin') {
    h.push(Routes.ADMIN_DASHBOARD);
  }
  if (role !== 'u') return <Route {...props} component={Login} />;
  else return <Route {...props} />;
};

const RootRoute = () => {
  const role = SafeFramework.getUserRole();
  const h = useHistory();

  if (role === 'admin') {
    h.push(Routes.ADMIN_DASHBOARD);
  } else if (role === 'admin') {
    h.push(Routes.USER);
  } else {
    h.push(Routes.LOGIN);
  }

  return <></>;
};

const CreateRoutes = React.memo(() => {
  const dispatch = useDispatch();

  // check if there is JWT token that we ask to verify against
  const loginExistRequested =
    SafeFramework.c.LOGIN + SafeFramework.c.EXIST + SafeFramework.c.REQUESTED;
  const role = SafeFramework.getUserRole();
  const isAskingForUser = SafeFramework.getLoading(loginExistRequested);
  const askedForUser = SafeFramework.getToggle(loginExistRequested);
  useEffect(() => {
    if (role === null && !isAskingForUser && !askedForUser) {
      SafeFramework.query(
        {
          dataType: loginExistRequested,
        },
        dispatch,
        {
          postCallHook: () => {
            SafeFramework.toggle(loginExistRequested, dispatch, true);
          },
          onSuccess: processLogin(dispatch),
        },
      );
    }
  }, [role, isAskingForUser, askedForUser]);

  log.i('CreateRoutes', {})();
  if (isAskingForUser === true) return <Loading />;

  return (
    <React.Suspense fallback={<Loading />}>
      <Switch>
        <Route exact component={RootRoute} path={Routes.ROOT} />

        <Route
          component={ContentDownload}
          path={Routes.CONTENT_DOWNLOAD + '/:id'}
        />
        <UserRoute component={UserDashboard} path={Routes.USER} />
        <AdminRoute component={AdminDashboard} path={Routes.ADMIN_DASHBOARD} />
        <Route exact component={Login} path={Routes.LOGIN} />
        <Route exact component={Register} path={Routes.REGISTER} />
        <Route component={NotFound} />
      </Switch>
    </React.Suspense>
  );
});
export default CreateRoutes;
