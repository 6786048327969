import log from 'safe-logger';

import * as gActions from '../App/globals/globalActions';
import * as gConsts from '../App/globals/globalConstants';
import Config from '../App/Config';
import SafeFramework from '@seifsg/safe-framework';
import { Cookies } from 'react-cookie';
import * as _Routes from '../App/routes/routes.json';
import jwtDecode from 'jwt-decode';

export const decodeJWT = <T>(token: string): T => jwtDecode(token);

export const Routes: typeof _Routes = (_Routes as any).default;

/**
 * Uppercase first letter in a string
 * @param {string} s
 * @returns {string}
 */
export const ucFirst = (s: string) => {
  return s.charAt(0).toUpperCase() + s.substr(1);
};

export const getCookie = (cookieName: string) => {
  const cookies = new Cookies();
  return cookies.get(cookieName);
};

/**
 * Safely prints an element inside of an object
 * @param {object} input
 * @param {string[]} arr
 * @returns {string}
 */
export const cop = (input: object, arr: string[]) => {
  try {
    if (!input) {
      return '';
    }

    let tmp: any = input;
    for (let i = 0; i < arr.length; i++) {
      const el = arr[i];
      if (tmp[el] !== undefined) {
        tmp = tmp[el];
      } else {
        return '';
      }
    }

    return tmp;
  } catch (error) {
    log.e('cell error', error, input, arr)();
    return '';
  }
}; // attempt to unify and simplify

/**
 * Gets a field from a map of fetched values
 * Otherwise it return undefined
 * @param state state of the function
 * @param getsField gets field from a very known hierarchy
 */
/* export const getStateField = (state: any, getsField: string) => {
    state.main.fetchedFileds.get(getsField);
}; */ export const formattedDateFromString = (
  date: string,
) => {
  try {
    return new Date(date).toLocaleString('en-GB', { hour12: true });
  } catch (error) {
    return '';
  }
};

/**
 * Generates a date in the future
 * @param years
 * @param months
 * @param days
 */
export const nextDate = (years: number, months: number, days: number) => {
  const newDate = new Date();
  if (days) {
    newDate.setDate(newDate.getDate() + days);
  }
  if (months) {
    newDate.setMonth(newDate.getMonth() + months);
  }
  if (years) {
    newDate.setFullYear(
      newDate.getFullYear() + years,
      newDate.getMonth() + months,
      newDate.getDay() + days,
    );
  }

  return newDate;
};

/**
 * Combines local and global actions for use with mapDispatchToProps
 * @param actions local redux actions
 * @returns combined global and local actions
 */
export const combineActions = <T>(actions: T): T & typeof gActions => ({
  ...actions,
  ...gActions,
});
export const combineConstants = <T>(
  c: T,
): T & typeof gConsts & typeof SafeFramework.c => ({
  ...SafeFramework.c,
  ...c,
  ...gConsts,
});

export const allConstants = () => ({
  ...SafeFramework.c,
  ...gConsts,
});

export const globalActions = gActions;

export const nullifyEmpty = (values: any) => {
  let _v = { ...values };
  Object.keys(values).map((k: string) => {
    if (typeof _v[k] === 'string') _v[k] = _v[k].trim() === '' ? null : _v[k];
    return undefined;
  });

  return _v;
};

export type PropertyOf<T> = T[keyof T];

export const concatSiteURL = (path: string) => Config.SITE_URL + path;

export function formatBytes(a: number, b = 2) {
  if (0 === a) return '0 Bytes';
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  );
}

export const stringToASCIISum = (s: string) => {
  if (typeof s !== 'string') return 0;
  let r = 0;
  s.split('').map((c) => {
    r += c.charCodeAt(0);
  });
  return r;
};
