import { allConstants } from '../lib/Functions';
import SafeFramework from '@seifsg/safe-framework';

const RegisterGlobalAPIs = () => {
  const c = allConstants();
  SafeFramework.api.register([
    {
      name: c.LOGIN,
      val: {
        method: 'POST',
        path: '/auth/signin',
      },
    },
    {
      name: c.LOGOUT,
      val: {
        method: 'POST',
        path: '/auth/signout',
      },
    },
    {
      name: c.LOGIN + c.EXIST + c.REQUESTED,
      val: {
        method: 'GET',
        path: '/auth/verifyJWT',
      },
    },
    {
      name: c.REGISTER,
      val: {
        method: 'POST',
        path: '/auth/signup',
      },
    },
    {
      name: c.CONTENT + c.GET,
      val: {
        method: 'GET',
        path: '/contents',
      },
    },
  ]);
};

export default RegisterGlobalAPIs;
