import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

// Importing rootReducer
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

class HCStore {
  static instance: HCStore;
  private store: any;
  static typeOfStore: any;
  constructor(initialState = {}) {
    this.createStore();
  }

  createStore() {
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [sagaMiddleware];
    const enhancers = [applyMiddleware(...middleware)];
    // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    const composeEnhancers =
      process.env.NODE_ENV !== 'production' &&
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

    const _store = createStore(
      rootReducer,
      {}, // initial state
      composeEnhancers(...enhancers),
    );

    type _TStoreExtended = {
      runSaga?: typeof sagaMiddleware.run;
      injectedReducers?: any;
      injectedSagas?: any;
    };

    const store: any = _store;

    // Extensions
    store.runSaga = sagaMiddleware.run;
    store.injectedReducers = {}; // Reducer registry
    store.injectedSagas = {}; // Saga registry

    store.runSaga(rootSaga);

    this.store = store;
  }

  static init() {
    if (!HCStore.instance) HCStore.instance = new HCStore();

    return HCStore.instance;
  }

  get Store() {
    return this.store;
  }
}

export default HCStore.init().Store;
