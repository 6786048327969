/* Reducers */
import { combineReducers } from 'redux';
import globalReducer from './globals/globalReducer';
import SafeFramework from '@seifsg/safe-framework';

const rootReducer = combineReducers({
  global: globalReducer,
  safe: SafeFramework.reducer,
});

export default rootReducer;
