import { decodeJWT } from './Functions';
import log from 'safe-logger';
import { Dispatch } from 'redux';
import SafeFramework from '@seifsg/safe-framework';
import Config from '../App/Config';

export const logoutProc = (dispatchFN: Dispatch<any>) => {
  SafeFramework.query(
    {
      dataType: SafeFramework.c.LOGOUT,
    },
    dispatchFN,
    {
      onSuccess: (d) => {
        if (d.t === 'S') {
          SafeFramework.clearUserData(dispatchFN);
          window.location.href = Config.SITE_URL + '/login';
        }
      },
    },
  );
};

export const processLogin = (dispatchFN: Dispatch<any>) => {
  return (data: { d: { jwtToken: string } }) => {
    if (typeof data.d?.jwtToken !== 'string') return;
    const user: {
      sub: string;
      firstName: string;
      lastName: string;
      role: string;
    } = decodeJWT(data.d.jwtToken);
    log.i('processLogin', { data, user })();
    SafeFramework.setInUser('id', user.sub, dispatchFN);
    SafeFramework.setInUser('firstName', user.firstName, dispatchFN);
    SafeFramework.setInUser('lastName', user.lastName, dispatchFN);
    SafeFramework.setInUser('ROLE', user.role, dispatchFN);
    log.i('processLogin', { d: SafeFramework.dataStore.data })();
  };
};
