const Config = {
  MODE: process.env.REACT_APP_MODE,
  JWT_COOKIE_NAME: process.env.REACT_APP_JWT_COOKIE_NAME,

  SERVER_URL_DEV: process.env.REACT_APP_SERVER_URL_DEV || '',
  SERVER_URL_STAG: process.env.REACT_APP_SERVER_URL_STAG || '',
  SERVER_URL_PROD: process.env.REACT_APP_SERVER_URL_PROD || '',
  SERVER_URL: '',

  WEBSOCKET_SERVER_DEV: process.env.REACT_APP_WEBSOCKET_SERVER_DEV || '',
  WEBSOCKET_SERVER_STAG: process.env.REACT_APP_WEBSOCKET_SERVER_STAG || '',
  WEBSOCKET_SERVER_PROD: process.env.REACT_APP_WEBSOCKET_SERVER_PROD || '',
  WEBSOCKET_SERVER: '',

  SITE_URL_DEV: process.env.REACT_APP_SITE_URL_DEV || '',
  SITE_URL_STAG: process.env.REACT_APP_SITE_URL_STAG || '',
  SITE_URL_PROD: process.env.REACT_APP_SITE_URL_PROD || '',
  SITE_URL: '',
};

Config.SERVER_URL =
  Config.MODE === 'dev'
    ? Config.SERVER_URL_DEV
    : Config.MODE === 'stag'
    ? Config.SERVER_URL_STAG
    : Config.SERVER_URL_PROD;
Config.SITE_URL =
  Config.MODE === 'dev'
    ? Config.SITE_URL_DEV
    : Config.MODE === 'stag'
    ? Config.SITE_URL_STAG
    : Config.SITE_URL_PROD;

export default Config;
