/*
Author: Seif Sgayer (seif@sghaier.me)
constants.ts (c) 2020
Desc: description
Created:  2020-05-01T16:45:45.157Z
Modified: 2020-05-05T02:43:01.369Z
*/

import SafeFramework from '@seifsg/safe-framework';

export const SELECTIONS = 'SELECTIONS';
export const MENU = 'MENU';
export const SETTINGS = 'SETTINGS';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ADMIN = 'ADMIN';
export const DATA = 'DATA';
export const LOADING = 'LOADING';
export const BOARD = 'BOARD';

export const REGISTER = 'REGISTER';
export const CONTENT = 'CONTENT';

export const WS_INITIATE = 'WS_INITIATE';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_CLOSE = 'WS_CLOSE';

const c = {
  ...SafeFramework.c,
};

export default c;
