import React from 'react';

import * as ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
import 'antd/dist/antd.css';
import './main.css';
// css import
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import CreateRoutes from './App/routes/Routes';
import Store from './App/GenerateStore';
import InitApp from './InitApp';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

InitApp();

const theme = createMuiTheme({
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: 'Proximanova',
    fontSize: 16,
  },
  palette: {
    primary: {
      main: '#00c2df',
    },
    secondary: {
      main: '#003464',
    },
  },
});

ReactDOM.render(
  <Provider store={Store}>
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={theme}>
        <Router history={createBrowserHistory()}>
          <CreateRoutes />
        </Router>
      </ThemeProvider>
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
